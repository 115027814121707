export const SUITABLE_FOR = {
  NORMAL: "normal",
  CHILD: "child",
  ADULT: "adult",
};

export const LANGUAGES = [
  { label: "Arabic", value: "ar", key: "ar" },
  { label: "Afrikaans", value: "af", key: "af" },
  { label: "Belarusian", value: "be", key: "be" },
  { label: "Bulgarian", value: "bg", key: "bg" },
  { label: "Czech", value: "cs", key: "cs" },
  { label: "Catalan", value: "ca", key: "ca" },
  { label: "Danish", value: "da", key: "da" },
  { label: "German", value: "de", key: "de" },
  { label: "Greek", value: "el", key: "el" },
  { label: "English", value: "en", key: "en" },
  { label: "Esperanto", value: "eo", key: "eo" },
  { label: "Spanish", value: "es", key: "es" },
  { label: "Estonian", value: "et", key: "et" },
  { label: "Farsi", value: "fa", key: "fa" },
  { label: "Finnish", value: "fi", key: "fi" },
  { label: "French", value: "fr", key: "fr" },
  { label: "Gujarati", value: "gu", key: "gu" },
  { label: "Galician", value: "gl", key: "gl" },
  { label: "Hindi", value: "hi", key: "hi" },
  { label: "Hebrew", value: "he", key: "he" },
  { label: "Croatian", value: "hr", key: "hr" },
  { label: "Hungarian", value: "hu", key: "hu" },
  { label: "Armenian", value: "hy", key: "hy" },
  { label: "Indonesian", value: "id", key: "id" },
  { label: "Icelandic", value: "is", key: "is" },
  { label: "Italian", value: "it", key: "it" },
  { label: "Japanese", value: "ja", key: "ja" },
  { label: "Korean", value: "ko", key: "ko" },
  { label: "Maori", value: "mi", key: "mi" },
  { label: "Dutch", value: "nl", key: "nl" },
  { label: "Punjabi", value: "pa", key: "pa" },
  { label: "Polish", value: "pl", key: "pl" },
  { label: "Portuguese", value: "pt", key: "pt" },
  { label: "Romanian", value: "ro", key: "ro" },
  { label: "Russian", value: "ru", key: "ru" },
  { label: "Sanskrit", value: "sa", key: "sa" },
  { label: "Swedish", value: "sv", key: "sv" },
  { label: "Tamil", value: "ta", key: "ta" },
  { label: "Telugu", value: "te", key: "te" },
  { label: "Thai", value: "th", key: "th" },
  { label: "Tagalog", value: "tl", key: "tl" },
  { label: "Turkish", value: "tr", key: "tr" },
  { label: "Urdu", value: "ur", key: "ur" },
  { label: "Vietnamese", value: "vi", key: "vi" },
  { label: "Chinese", value: "zh", key: "zh" },
];


// Staging Server URLs
// export const API_ENDPOINT = `https://api.xik.io/api/`;
// export const SOCKET_URL_ENDPOINT = "https://api.xik.io/";

// export const ADMIN_URL = "https://staging.atz.ai/"
// export const WEBSITE_URL = "https://staging-web.atz.ai/"

// export const ADMIN_DOMAIN_NAME = "staging.atz.ai"
// export const WEBSITE_DOMAIN_NAME = "staging-web.atz.ai"


/**
 * For PRODUCTIONS Environment
 */

export const API_ENDPOINT = `https://api.atz.ai/api/`;
export const SOCKET_URL_ENDPOINT = "https://api.atz.ai/";

export const ADMIN_URL = "https://app.atz.ai/"
export const WEBSITE_URL = "https://atz.ai/"

export const ADMIN_DOMAIN_NAME = "app.atz.ai"
export const WEBSITE_DOMAIN_NAME = "atz.ai"